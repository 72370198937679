import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const BlockHomeTwo = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="counter-area bg-gray">
      <div className="container">
        <div
          className="counter-area-inner pd-top-120 pd-bottom-120"
          style={{
            backgroundImage: 'url("' + publicUrl + 'assets/img/other/1.png")',
          }}
        >
          
          <div className="row">

          <div className="col-lg-4 align-self-center">
            <div
                className="about2"
                style={{ backgroundImage:`url("${rpdata?.gallery?.[17]}")`}}
              ></div>
            </div>

            <div className="col-lg-8 mb-5 mb-lg-0">
              <div className="section-title mb-0">
                <h6 className="sub-title right-line">
                  {rpdata?.dbSlogan?.[4].slogan}
                </h6>
                <h2 className="title">{rpdata?.dbSlogan?.[3].slogan}</h2>
                <p className="content pb-3">{rpdata?.dbHome?.[1].text}</p>
                <Link
                  className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                  to="/contact"
                >
                  Free Estimate
                </Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockHomeTwo;
