import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "photoswipe/dist/photoswipe.css";
import GalleryContent from "../gallerry-components/GalleryContent";
// import { Gallery, Item } from "react-photoswipe-gallery";

const GalleryPage = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="container pd-top-150 pd-bottom-100">
      <h2 className="text-center">Our Recent Projects</h2>
      
      {/* <Gallery>
        {rpdata?.gallery?.map((item, index) => (
          <Item original={item} thumbnail={item}  padding="10px">
            {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="notImg" />}
          </Item>
        ))}
      </Gallery> */}
      {
        rpdata?.landings?.map((item, index) => {
          return (
            <div className="pt-20 pb-32 container text-center">
              <h2 className="capitalize">{item.name}</h2>
              <GalleryContent galleryImages={item.gallery} />
            </div>
          )
        })
      }
    
    </div>
    
  );
};

export default GalleryPage;
